//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    // selProject: () => import("@/components/Selectors/selPro"),
  },
  props: {
    teamId: {
      type: Number,
      default: 0,
    },
    selEditPlan: {
      type: Object,
      default: null,
    },
    defaultEid: {
      type: Number | String,
      default: null,
    },
  },
  data() {
    var checkPro = (rule, value, callback) => {
      if (!this.form.proId) {
        callback(new Error("请选择项目"));
      } else {
        callback();
      }
    };
    return {
      proList: [],
      userList: [],
      form: {
        name: null,
        proId: null,
      },
      rules: {
        name: [{ required: true, message: "请输入计划名称", trigger: "blur" }],
        pro: [{ validator: checkPro, trigger: "blur" }],
      },
    };
  },
  methods: {
    /**
     * 确定添加
     */
    onConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.addPlan.loadBtn(true);
          let data = {
            name: this.form.name,
            proId: this.defaultEid ? this.defaultEid : this.form.proId,
            participant: this.userList.map((m) => m.UsId),
            teamId: this.teamId,
          };
          if (this.selEditPlan) {
            data.pId = this.selEditPlan.id;
          }
          this.$http
            .post("/Task/TaskPlan/SaveTaskPlan.ashx", data)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "操作成功",
                  type: "success",
                });
                this.$emit("success");
                this.$modal.hide("addPlan");
              }
            })
            .finally(() => this.$refs.addPlan.loadBtn(false));
        } else {
          return;
        }
      });
    },
    /**
     * 打开弹窗回调
     */
    opened() {
      this.$nextTick(() => {
        if (this.selEditPlan) {
          this.form.name = this.selEditPlan.pName;
          this.proList = [this.selEditPlan.proId];
          this.form.proId = this.selEditPlan.proId;
          this.userList = this.selEditPlan.participants;
        }
      });
    },
    /**
     * 弹窗关闭回调
     */
    closed() {
      this.form.name = null;
      this.proList = [];
      this.form.proId = null;
      this.userList = [];
    },
    /**
     * 获取成员
     */
    getUser(val) {
      this.userList = val;
    },
    /**
     * 获取项目
     */
    getProject(val) {
      this.form.proId = val.length ? val[0].proId : null;
    },
  },
};
